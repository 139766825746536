<template>
  <!-- 查看问卷调查 -->
  <div class="view_questionnaire">
    <CommonQuestionnaire ref="commonQuestionnaire"
                         :autoid="mautoid"
                         :username="username"></CommonQuestionnaire>
  </div>
</template>

<script>
import CommonQuestionnaire from './commonQuestionnaire'
// exitPrompt
export default {
  components: {
    CommonQuestionnaire
  },
  data () {
    let mautoid = this.$route.query.mautoid
    let username = this.$route.query.username
    return {
      mautoid,
      username
    }
  }

};
</script>
 
 
<style lang="less" scoped>
.view_questionnaire {
  width: 100%;
  height: 100%;
}
</style>